import React, { useState } from 'react';
import Button from '@mui/material/Button';

function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      setSelectedFile(null);
      setFileName('');
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      let api_url = 'https://tpp-spss-api.thepeopleplatform.com/upload';
      const response = await fetch(api_url, {
        method: 'POST',
        body: formData,
      });
    
      if (!response.ok) {
        const errorBody = await response.json();
        const errorMessage = errorBody.message || 'Upload failed';
        alert(errorMessage);
        return;
      } else {
        const responseBody = await response.json();
        window.location = responseBody.url;
        return;
      }

    } catch (error) {
      console.error('Fetch error: ', error);
      alert('Upload failed - Network or other error');
    }
    
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
      <Button
        variant="contained"
        component="label"
        style={{ margin: '10px' }}
      >
        Choose File
        <input
          type="file"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      {fileName && <p>Selected file: {fileName}</p>}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={!selectedFile}
      >
        Upload
      </Button>
    </div>
  );
}

export default FileUpload;